<template>
  <div>
    <div>
      <a href="javascript:;" @click="getDetail()">
        <span v-if="!internalVendorName">
          <slot></slot>
        </span>
        <span v-else>{{ internalVendorName }}</span>
      </a>
    </div>
    <app-dialog v-model="dialog" title="Vendor Info" @onClose="showDetail = false">
      <template #toolbar>
        <app-button @click="getDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
      </template>
      <v-row>
        <v-col cols="12" md="3">
          <v-card>
            <div class="text-center">
              <v-avatar size="100">
                <v-img :src="require('@/assets/images/no-img.png')"></v-img>
              </v-avatar>
            </div>
            <v-card-text>
              <v-list dense class="compact">
                <v-list-item>
                  <v-list-item-title>Vendor Name</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.vendor.name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Phone No.</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.vendor.phone_no }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Address</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.vendor.address }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>City</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.regency.name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Province</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.province.name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Country</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.country.name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Postal Code</v-list-item-title>
                  <v-list-item-subtitle>{{ dataDetail.vendor.post_code }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <card-expansion hide-title>
            <template #title>
              <v-tabs v-model="tab">
                <v-tab>General</v-tab>
                <v-tab>Attahments</v-tab>
                <v-tab>PIC</v-tab>
                <v-tab>Offers</v-tab>
              </v-tabs>
            </template>
            <template>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-list dense class="compact">
                    <v-list-item>
                      <v-list-item-title>Vendor Name:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>NPWP:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.npwp }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Phone No.:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.phone_no }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Email:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.email }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Website:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.website }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Bidang Usaha:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.business_fields }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Status:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.status }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <h3>Address</h3>
                  <v-list dense class="compact">
                    <v-list-item>
                      <v-list-item-title>Address:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.address }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>City:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.regency.name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Province:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.province.name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Country:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.country.name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Postal Code:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.post_code }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-tab-item>
                <v-tab-item>
                  <v-list dense class="compact">
                    <v-list-item>
                      <v-list-item-title>NPWP:</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.npwp_src" :href="dataDetail.vendor.npwp_src" target="_blank">{{ dataDetail.vendor.npwp_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>KTP:</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.ktp_src" :href="dataDetail.vendor.ktp_src" target="_blank">{{ dataDetail.vendor.ktp_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Catalog</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.catalog_src" :href="dataDetail.vendor.catalog_src" target="_blank">{{ dataDetail.vendor.catalog_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>NIB</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.nib_src" :href="dataDetail.vendor.nib_src" target="_blank">{{ dataDetail.vendor.nib_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Company Profile</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.company_profile_src" :href="dataDetail.vendor.company_profile_src" target="_blank">{{ dataDetail.vendor.company_profile_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Cerificate</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.certificate_src" :href="dataDetail.vendor.certificate_src" target="_blank">{{ dataDetail.vendor.certificate_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Licensing Document</v-list-item-title>
                      <v-list-item-subtitle>
                        <a v-if="dataDetail.vendor.licensing_document_src" :href="dataDetail.vendor.licensing_document_src" target="_blank">{{ dataDetail.vendor.licensing_document_src }}</a>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-tab-item>
                <v-tab-item>
                  <v-list dense class="compact">
                    <v-list-item>
                      <v-list-item-title>Name:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.pic_name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Position:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.pic_position }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Phone No.:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.pic_phone_no }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Email:</v-list-item-title>
                      <v-list-item-subtitle>{{ dataDetail.vendor.pic_email }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-tab-item>
                <v-tab-item>
                  <v-data-table :headers="headers" :items="dataDetail.offer" :items-per-page="5" class="elevation-1" dense>
                    <template v-slot:[`item.document_no_`]="{ item }">
                      <router-link :to="'/purchasing/offer/detail?id=' + item.id">{{ item.document_no_ }}</router-link>
                    </template>
                    <template v-slot:[`item.tender_document_no_`]="{ item }">
                      <router-link :to="'/purchasing/tender/detail?id=' + item.tender_header_id">{{ item.tender_document_no_ }}</router-link>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <app-document-status v-model="item.status"></app-document-status>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </card-expansion>
        </v-col>
      </v-row>

    </app-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  name: 'app-vendor-info',
  props: {
    vendorId: {
      type: Number,
      default: 0,
    },
    vendorName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dataDetail: {
        vendor: {},
        user: {},
        country: {},
        province: {},
        regency: {},
        district: {},
        offer: [],
      },
      showDetail: false,
      tab: null,
      internalVendorName: this.vendorName,

      headers: [
        {
          text: "Doc. No",
          value: "document_no_",
          align: "left",
        },
        {
          text: "Tender No",
          value: "tender_document_no_",
          align: "left",
        },
        {
          text: "Status",
          value: "status",
          align: "left",
        },
        {
          text: "Created Date",
          value: "created_date",
          align: "left",
        },
        {
          text: "Created By",
          value: "created_by_username",
          align: "left",
        },
        {
          text: "Last Modified",
          value: "last_modified",
          align: "left",
        },
      ]
    };
  },
  watch: {
    vendorName(val) {
      this.internalVendorName = val;
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
      this.$emit('onShow');
    },
    hideDialog() {
      this.dialog = false;
      this.$emit('onClose');
    },
    getDetail() {
      this.showLoadingOverlay(true);
      var params = {
        id: this.vendorId,
      };
      this.$axios
        .get("vendor/info", {
          params: params,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          this.dataDetail = res.data.data;
          this.showDetail = true;

          this.showDialog();
        })
        .catch((err) => {
          this.dialog = false;
          this.showLoadingOverlay(false);
          this.showSnackbar(err.response.data.message, "error");
        });
    },
  },
}
</script>