<template>
  <v-sheet color="transparent" elevation="0" class="mb-1">
    <div class="d-flex" v-if="!block">
      <v-col class="py-0 text-start d-flex font-weight-bold " lg="4" md="4" sm="4" cols="12">
        <label @click="onClickLabel(internalValue)" class="d-flex">
          {{ label }} <span class="d-flex d-sm-none ml-2">:</span><span class="d-none d-sm-flex flex-end ml-auto">:</span>
        </label>
      </v-col>
      <v-col class="py-0 text-start">
        <p class="my-0" v-if="internalValue != null" :class="valueAlign" @click="onClickValue(internalValue)">{{ (internalValue == "") ? "&nbsp;" : internalValue }}</p>
        <p class="my-0" v-else :class="valueAlign" @click="onClickValue(internalValue)">
          <slot></slot>
        </p>
        <small v-if="hint != ''" style="color: #ddd;font-size: 9pt;" class="py-0 my-0">{{ hint }}</small>
      </v-col>
    </div>
    <div class="d-flex flex-column" v-else>
      <v-col class="py-0 text-start d-flex font-weight-bold" cols="12">
        <label @click="onClickLabel(internalValue)">
          {{ label }} <span class="ml-2">:</span>
        </label>
      </v-col>
      <v-col class="py-0 text-start">
        <p class="my-0" style="color: grey;" v-if="internalValue != null" :class="valueAlign" @click="onClickValue(internalValue)" v-html="(internalValue == '') ? '&nbsp;' : internalValue"></p>
        <p class="my-0" v-else :class="valueAlign" @click="onClickValue(internalValue)">
          <slot></slot>
        </p>
        <small v-if="hint != ''" style="color: #ddd;font-size: 9pt;" class="py-0 my-0">{{ hint }}</small>
      </v-col>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'app-data-line',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    value: null,
    valueAlign: {
      type: String,
      default: 'text-justify'
    },
    block: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        if (typeof this.callback == 'function')
          this.callback();
      }
    },
  },
  methods: {
    onClickValue(val) {
      this.$emit("click:value", val);
    },
    onClickLabel(val) {
      this.$emit("click:label", val);
    },
  },
}
</script>