<template>
  <div v-if="internalValue != '' && internalValue.length > 0">
    <v-chip v-bind="$attrs" v-on="$listeners" :color="chipColor" :small="internalSmall" :dark="darkColorList[chipColor]">{{ ucFirstAll(internalValue) }}</v-chip>
  </div>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    value: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      internalValue: this.value,
      internalColor: this.color,
      internalSmall: this.small,
      internalDark: this.dark,

      colorList: {
        'open': 'grey',
        'draft': 'grey lighten-2',
        'waiting-approval': 'blue',
        'approved': 'green',
        'rejected': 'red',
        'closed': 'orange',
        'received': 'blue',
        'on-progress': 'blue',
        'in-progress': 'blue',
        'on-process': 'blue',
        'waiting-sparepart': 'warning',
        'pending': 'orange',
        'done': 'green',
        'released': 'green',
        'verified': 'green',
        'submitted': 'green',
        'under-review': 'orange',              // Penawaran sedang dievaluasi
        'incomplete': 'blue',                  // Dokumen tidak lengkap
        'clarification-requested': 'grey',     // Klarifikasi tambahan diminta
        'accepted': 'green',                   // Penawaran diterima
        'disqualified': 'yellow darken-3',              // Penawaran gugur
        'shortlisted': 'blue',                 // Masuk dalam daftar pendek
        'withdrawn': 'blue',                   // Penawaran ditarik
        'awarded': 'green',                    // Pemenang tender
        'contract-signed': 'green',            // Kontrak telah ditandatangani
        'expired': 'grey',                    // Penawaran kedaluwarsa
        'cancelled': 'red darken-3',                  // Proses tender dibatalkan
        'on-hold': 'warning',                  // Proses ditunda sementara
      },
      darkColorList: {
        "grey": true,
        "grey lighten-2": false,
        "blue": true,
        "green": true,
        "red": true,
        "orange": true,
        "warning": true,
        "yellow darken-3": true,
        "red darken-3": true,
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.internalValue = val
        this.internalColor = this.colorList[val]

      } else {
        this.internalColor = 'grey'
        this.internalValue = '-'
      }
    },
    internalValue(val) {
      this.$emit('input', val);
    },
    small(val) {
      if (val) {
        this.internalSmall = val
      }
    },
    color(val) {
      if (val) {
        this.internalColor = val
      }
    }
  },
  computed: {
    chipColor() {
      if (this.internalColor != '') {
        return this.internalColor
      }

      const color = this.colorList;

      if (color[this.value]) {
        return color[this.value];
      } else {
        return 'grey';
      }
    }
  }
}
</script>